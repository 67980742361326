<template>
  <div class="right_side">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">txHash</th>
        <th scope="col">Address</th>
        <th scope="col">Value</th>
        <th scope="col">Conf.</th>
        <th scope="col">Date</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="req in history" v-bind:key="req.txHash">
        <td><a :href="'https://live.blockcypher.com/ltc/tx/' + req.txHash"
               target="_blank"
               rel="noopener noreferrer">
          {{ req.txHash }}
        </a></td>
        <td>{{ req.ltcAddress.address }}</td>
        <td><div :class="[req.value < 0 ? 'color_red' : 'color_green']">{{ req.value }}</div></td>
        <td><div :class="[req.confirmations < 3 ? 'color_red' : 'color_green']">{{ req.confirmations | confirmationCount }}</div></td>
        <td>{{ req.createdDate | moment("DD.MM.YYYY HH:m:ss") }}</td>
      </tr>
      </tbody>
    </table>



  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'LtcTransaction',
  props: ['type'],

  data: function () {
    return {
      history: {},
      currentRequest: {},
    }
  },

  computed: {
    ...mapGetters(['user', 'token']),
  },

  watch: {
    type: function () {
      this.fetch()
    }
  },

  methods: {
    ...mapActions(["setMaintaining"]),
    fetch: function () {
      axios.get('/api/admin/ltchistory', {
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      }).then(
          response => {
            this.history = response.data
          }
      );
    }
  },

  filters: {
    confirmationCount: function (value) {
      if (value >= 6) {
        return '6+'
      }
      return value
    }
  },

  mounted() {

    if (this.user != null &&
        !this.user.roles.includes('ADMIN')) {
      this.$router.push('/')
    }
    this.fetch()
  }
}
</script>